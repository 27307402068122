// import locales from '@roolz/i18n'

// export enum LOCALES {
//   ENGLISH = 'en',
//   RUSSIAN = 'ru',
//   CHINESE = 'zh-cn',
//   FRENCH = 'fr-fr',
//   GERMAN = 'de',
//   HINDI = 'hi-in',
//   POLISH = 'pl-pl',
//   SPANISH = 'es-es',
//   TURKISH = 'tr',
//   UKRAINIAN = 'uk'
// }

export enum LocalesWithCountry {
  De = 'de',
  En = 'en',
  Es = 'es_ES',
  Fr = 'fr_FR',
  Hi = 'hi_IN',
  Pl = 'pl_PL',
  Ru = 'ru',
  Tr = 'tr',
  Uk = 'uk',
  Zh = 'zh_CN',
}

export const LOCALE_FLAGS: any = {
  de: 'de',
  en: 'gb',
  es: 'es',
  fr: 'fr',
  hi: 'in',
  pl: 'pl',
  ru: 'ru',
  tr: 'tr',
  uk: 'ua',
  zh: 'cn',
}

export const DEFAULT_LANGUAGE = 'en'
